/* globals prestashop */

import React from "react";
import axios from "axios";

const {format} = require('number-currency-format');

class ProductCustomize_API {

    static api_url = '/module/pshowproductcustomize/api'

    static getProductCustomizationSchema(idProduct) {
        return axios.get(this.api_url + '?ajax=1&action=getProductCustomizationSchema&id_product=' + idProduct)
            .then(response => {
                return response.data.customization_schema;
            });
    }

}

class ProductCustomizationSchemaGroupComponent {
    id_product_customization_component = "0"; // id opcji
    name = ""; // nazwa opcji
    id_customization_group = "0";
    default_on = false; // czy domyślnie zaznaczone
    price_impact = "0.00"; // wpływ na cenę produktu
    id = "";
}

class ProductCustomizationSchemaGroup {
    id_product_customization_group = "0"; // id grupy składników
    name = ""; // nazwa grupy składników
    id_product = "0"; // id produktu
    type = "0";
    choices_min = "0"; // minimum tyle opcji tego składnika użytkownik musi wybrać przed dodaniem do koszyka
    choices_max = "0"; // maks. ilość opcji ile można wybrać, 0 = bez limitu
    id = "0";
    components = [
        // ProductCustomizationSchemaGroupComponent,
        // ProductCustomizationSchemaGroupComponent,
        // ...
    ]
}

export default class ProductCustomize_Popup extends React.Component {

    state = {
        // clean customization schema
        productCustomizationSchema: false,
        // productCustomizationSchema: [
        //     ProductCustomizationSchemaGroupComponent,
        //     ProductCustomizationSchemaGroupComponent,
        //     ...
        // ],

        currentProductId: 0,
        currentProductCustomization: [
            // {
            //     id_group: int,  // id of the component's group
            //     components: [   // selected components only
            //         {
            //             id_component: int,
            //         },
            //         ...
            //     ]
            // },
        ],
        currentProductCustomizationValidationErrors: {
            // idGroup: [
            //     // ... errors
            // ]
        },
        currentProductCustomizationValidationErrorsExists: false,
    }

    componentDidMount() {
        this.fetchProductCustomizationSchema();
    }

    componentDidUpdate() {
        if (this.state.currentProductId !== this.props.productId) {
            this.setState({
                productCustomizationSchema: false,
                currentProductCustomization: [],
                currentProductCustomizationValidationErrors: [],
                currentProductCustomizationValidationErrorsExists: false,
                currentProductId: this.props.productId,
            });
            this.fetchProductCustomizationSchema();
        }
    }

    fetchProductCustomizationSchema() {
        ProductCustomize_API.getProductCustomizationSchema(this.props.productId).then(customizationSchema => {
            this.setState({productCustomizationSchema: customizationSchema});
            this.prepareNewProductCustomizationObject();
            this.refresh();
        });
    }

    /**
     * Prepare new customization object based on schema retrieved from api
     */
    prepareNewProductCustomizationObject() {
        let currentProductCustomization = [];
        if (this.state.productCustomizationSchema.map) {
            this.state.productCustomizationSchema = this.state.productCustomizationSchema.filter(group => {
                return group?.components && typeof group?.components?.map === 'function';
            });
            this.state.productCustomizationSchema.map(group => {
                if (!group?.components || typeof group?.components?.map !== 'function') {
                    return;
                }
                let _group = {
                    id_group: group.id,
                    components: []
                }
                group.components.map(component => {
                    if (component.default_on) {
                        _group.components.push({
                            id_component: component.id
                        })
                    }
                });

                currentProductCustomization.push(_group);
            });
        }
        this.setState({currentProductCustomization: currentProductCustomization});
        this.validateGroupComponentSelections();
    }

    /**
     * Check if group component has been selected by customer
     *
     * @param groupId
     * @param componentId
     */
    isCurrentCustomizationComponentSelected(groupId, componentId) {
        return this.state.currentProductCustomization.some(group => {
            return group.components.some(component => component.id_component === componentId);
        });
    }

    /**
     * Toggle group component selection
     *
     * @param groupId
     * @param componentId
     */
    toggleGroupComponentSelection(groupId, componentId) {
        let currentProductCustomization = this.state.currentProductCustomization;

        // find group and component indexes
        let groupIndex = -1;
        let componentIndex = -1;
        for (let i in currentProductCustomization) {
            let group = currentProductCustomization[i];
            if (groupId === group.id_group) {
                groupIndex = i;

                let components = currentProductCustomization[groupIndex].components;
                for (let x in components) {
                    let component = components[x];
                    if (componentId === component.id_component) {
                        componentIndex = x;
                        break;
                    }
                }

                break;
            }
        }
        if (groupIndex < 0) {
            alert(`Group ${groupId} not found!`);
            return;
        }

        // add or remove from selection array
        if (componentIndex < 0) {
            // remove other selections in this group if max_choices == 1 to simulate input[type=radio]
            let schema = this.findProductCustomizationSchemaGroupById(groupId);
            if (parseInt(schema.choices_max) === 1) {
                currentProductCustomization[groupIndex].components = [];
            }

            currentProductCustomization[groupIndex].components.push({
                id_component: componentId
            });
        } else {
            currentProductCustomization[groupIndex].components.splice(componentIndex, 1);
        }
        this.setState({currentProductCustomization: currentProductCustomization});
    }

    /**
     * Find customization schema group by id
     * @param groupId
     * @returns ProductCustomizationSchemaGroup
     */
    findProductCustomizationSchemaGroupById(groupId) {
        for (let i in this.state.productCustomizationSchema) {
            if (this.state.productCustomizationSchema[i].id_product_customization_group === groupId) {
                return this.state.productCustomizationSchema[i];
            }
        }
    }

    /**
     * Validate all selections and service errors
     */
    validateGroupComponentSelections() {
        let validationErrors = {};
        let validationErrorsExists = false;
        let currentProductCustomization = this.state.currentProductCustomization;
        for (let i in currentProductCustomization) {
            let group = currentProductCustomization[i];
            let schema = this.findProductCustomizationSchemaGroupById(group.id_group);
            let numOfSelectedComponents = group.components.length;

            validationErrors[group.id_group] = [];

            if (parseInt(schema.choices_max) !== 0 && parseInt(schema.choices_max) < numOfSelectedComponents) {
                validationErrors[group.id_group].push(`Możesz wybrać maksymalnie ${schema.choices_max} składnik/i`);
            } else if (parseInt(schema.choices_min) !== 0 && parseInt(schema.choices_min) > numOfSelectedComponents) {
                validationErrors[group.id_group].push(`Wybierz minimum ${schema.choices_min} składnik/i`);
            } else {
                continue;
            }

            validationErrorsExists = true;
        }
        this.setState({
            currentProductCustomizationValidationErrors: validationErrors,
            currentProductCustomizationValidationErrorsExists: validationErrorsExists,
        });
    }

    /**
     * Called when component selection changed
     *
     * @param groupId
     * @param componentId
     */
    onCurrentCustomizationComponentChange(groupId, componentId) {
        this.toggleGroupComponentSelection(groupId, componentId);
        this.refresh();
    }

    refresh() {
        this.validateGroupComponentSelections();
        this.updateProductPriceTotal();
    }

    /**
     * Toggle attribute 'disabled' of 'add to cart' button
     */
    toggleStateOfAddToCart() {
        let addToCartBtn = document.querySelector('.confirm-product-qty-order-container .add-to-cart');
        if (this.state.currentProductCustomizationValidationErrorsExists) {
            addToCartBtn?.setAttribute('disabled', 'true');
        } else {
            addToCartBtn?.removeAttribute('disabled');
        }
    }

    /**
     * Calculate total price and print
     */
    updateProductPriceTotal() {
        let total = parseFloat(this.props.productPriceBase);

        // sum selected components prices
        let currentProductCustomization = this.state.currentProductCustomization;
        for (let i in currentProductCustomization) {
            let group = currentProductCustomization[i];
            let schema = this.findProductCustomizationSchemaGroupById(group.id_group);
            for (let x in group.components) {
                for (let y in schema.components) {
                    if (group.components[x].id_component === schema.components[y].id_product_customization_component) {
                        total += parseFloat(schema.components[y].price_impact);
                        break;
                    }
                }
            }
        }

        document.querySelector('.confirm-product-qty-order-container .price').innerHTML = format(
            total,
            {
                currency: prestashop.currency.sign
            }
        );
    }

    render() {
        if (this.state.productCustomizationSchema === false) {
            return <div className={"ajax-loader"}>&nbsp;</div>;
        }

        if (!this.state.currentProductCustomization || !this.state.currentProductCustomization.length) {
            return null;
        }

        this.toggleStateOfAddToCart();

        const renderCustomizationComponent = (group, component) => {
            return (
                <label key={component.id}>
                    <input name={group.name} type="checkbox"
                           checked={this.isCurrentCustomizationComponentSelected(group.id, component.id)}
                           onChange={() => this.onCurrentCustomizationComponentChange(group.id, component.id)}
                    />
                    <div className="dough-item">
                        <div className="dough-item-title">{component.name}</div>
                        <div className="dough-item-price">
                            {component.price_impact > 0
                                ? <>+ {component.price_impact} zł</>
                                : <>&nbsp;</>
                            }
                        </div>
                    </div>
                </label>
            )
        }

        const renderErrors = (groupId) => {
            return (
                <div>
                    {this.state.currentProductCustomizationValidationErrors[groupId]?.map(errorMsg => {
                        return (
                            <span className={"badge badge-danger p-2"} key={errorMsg}>
                                {errorMsg}
                            </span>
                        )
                    })}
                </div>
            )
        }

        const result = (
            <div class="react_productcustomize_popup_content" key={this.props.productId}>
                {this.state.productCustomizationSchema.map(group => {
                    return (
                        <div className="product-additions-details-item" key={group.id}>
                            <div className="product-addition-details-title">
                                <span>
                                    {group.name}
                                </span>
                                {renderErrors(group.id)}
                            </div>
                            <div className="visible-dough-list">
                                {group.components.map(component => {
                                    return renderCustomizationComponent(group, component);
                                })}
                            </div>
                        </div>
                    );
                })}
            </div>
        )

        window.productCustomizePopup_refreshButtonVisibility();

        return result;
    }
}
