import './components/mobile-menu';

// Add function for show-more-btn to show more products in order-confirmation
if (document.querySelector('.show-more-products-btn')) {
    const showMoreProductsBtn = document.querySelector('.show-more-products-btn');

    showMoreProductsBtn.addEventListener("click", function () {

        if (showMoreProductsBtn.innerHTML === 'Show less') {
            const productItemsVisible = document.querySelectorAll('.product-item-visible');

            productItemsVisible.forEach(element => {
                element.classList.remove('product-item-show');
                element.classList.add('product-item-hidden');
            });

            showMoreProductsBtn.innerHTML = 'Show more';
        } else {
            const productItemsHidden = document.querySelectorAll('.product-item-hidden');

            // console.log(productItemsHidden);

            productItemsHidden.forEach(element => {
                element.classList.remove('product-item-hidden');
                element.classList.add('product-item-visible');
            });

            showMoreProductsBtn.innerHTML = 'Show less';
        }

    });
}


// Delivery localization map - leaflet
import 'leaflet';

const bodyOrderConfirmation = document.querySelector('#order-confirmation');

if (bodyOrderConfirmation) {
    let map = L.map('map').setView([51.505, -0.09], 13);
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(map);
}

// footer map
let footerMap = L.map('footerMap').setView([51.505, -0.09], 13);
L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(footerMap);


// Set cursor on input after click Apply promocode
$(document).ready(function () {
    $(".display-promo > .collapse-button").click(function (e) {
        setTimeout(function () {
            $(".promo-input").focus();
        }, 1000);
    })
});
$(document).on('click', '#left-column .category-top-menu a', function (e) {
    e.preventDefault();
    let categoryId = $(this).attr('data-category-id');
    let categoryHeader = $('#main #product-category-id-' + categoryId);

    let productMarginBottom = parseInt($('.products').css('margin-bottom'));
    let productPaddingTop = parseInt($('.products').css('padding-top'));

    $('html, body').animate({
        scrollTop: $(categoryHeader).offset().top - (productMarginBottom + productPaddingTop)
    }, 800);
});

$(document).on('click', '#left-column-mobile .category-top-menu a', function (e) {
    e.preventDefault();
    let thisAnchor = $(this);

    $('.header-nav .menu-icon').trigger('click');

    $('body').on('transitionend webkitTransitionEnd oTransitionEnd', function () {
        let categoryId = thisAnchor.attr('data-category-id');
        let categoryHeader = $('#main #product-category-id-' + categoryId);

        let productMarginBottom = parseInt($('.products').css('margin-bottom'));
        let productPaddingTop = parseInt($('.products').css('padding-top'));
        let mainContentMarginTop = parseInt($('.main-content').css('margin-top'));

        $('html, body').animate({
            scrollTop: $(categoryHeader).offset().top - (productMarginBottom + productPaddingTop + mainContentMarginTop)
        }, 800);
        $(this).off('transitionend webkitTransitionEnd oTransitionEnd');
    });

});

// Auto hide alert on voucher
$(".addVoucher").on("click", function () {
    setTimeout(function () {
        $(".alert").css("display", "none");
    }, 2000);
});

if ($(window).width() < 991) {
    $(document).ready(function () {
        $(".desktop-qtyItemBasket").remove();
        updateCard();
    })
}

$(window).on('resize', function () {
    // Update price and qty collapsed checkout
    if ($(window).width() < 991) {
        $(document).ready(function () {
            $(".desktop-qtyItemBasket").remove();
            updateCard();
        })
    }
});

function updateCard() {
    var qtyCard = $(".label.js-subtotal").text();
    var totalPriceCard = $(".cart-total > .value").text();
    $(".cart-summary-title").html(qtyCard);
    $(".cart-totals-title").html(totalPriceCard);
}

if ($(window).width() > 991) {
    //  show black background around enterLocation section on focusing "address_selector" input
    $("input#address_selector").on('focus', function () {
        $('.enterLocation-external-focus-section').fadeIn();
    })

    // Hide black background around enterLocation section on clicking outside enterLocation section
    $(".enterLocation-external-focus-section").on('click', function () {
        $('.enterLocation-external-focus-section').fadeOut();
    })
}

if ($(window).width() < 991) {
    $(".enterLocation").off().on('focusout', function () {
        $('#_desktop_logo').parent().css({'flex-direction': 'column'});
    })

    $("input#address_selector").on('focus', function () {
        $('#_desktop_logo').parent().css({'flex-direction': 'column-reverse'});

        $('html, body').animate({scrollTop: 0}, 'fast');
    })
}

$(window).on('resize', function () {
    if ($(window).width() < 991) {
        $(".enterLocation").off().on('focusout', function () {
            $('#_desktop_logo').parent().css({'flex-direction': 'column'});
        })

        $("input#address_selector").on('focus', function () {
            // $('#_desktop_logo').parent().css({'flex-direction': 'column-reverse'});

            $('html, body').animate({scrollTop: 0}, 'fast');
        })
    } else {
        $('#_desktop_logo').parent().attr({'style': ''});
        $(".enterLocation").off('focusout');
        // $("input#address_selector").off('focus')
    }

    if ($(window).width() > 991) {
        //  show black background around enterLocation section on focusing "address_selector" input
        $("input#address_selector").on('focus', function () {
            $('.enterLocation-external-focus-section').fadeIn();
        })

        // Hide black background around enterLocation section on clicking outside enterLocation section
        $(".enterLocation-external-focus-section").on('click', function () {
            $('.enterLocation-external-focus-section').fadeOut();
        })
    }
    else {
        $("input#address_selector").off('focus')
    }
})
