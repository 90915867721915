import React from "react";
import * as ReactDOM from "react-dom";
import ProductCustomize_Popup from "./react/productcustomize/productcustomize-popup.jsx";

$(document).ready(function () {
    (function ($) {
        $.fn.spinner = function () {
            this.each(function () {
                let el = $(this);

                // add elements
                el.wrap('<div class="spinner"></div>');
                el.before('<div class="subSign"></div>');
                el.after('<div class="addSign"></div>');

                // substract
                el.parent().on('click', '.subSign', function () {
                    if (el.val() > parseInt(el.attr('min'))) {
                        el.val(function (i, oldval) {
                            return --oldval;
                        });
                    }

                    if ($(window).width() <= 991) {
                        if (el.val() > 0) {
                            $(this).closest('.toppings-item').find('.toppings-price').css("color", "#193fff");
                        } else {
                            $(this).closest('.toppings-item').find('.toppings-price').css("color", "#91969f");
                        }
                    } else {
                        if (el.val() > 0) {
                            $(this).closest('.toppings-item').css("border-color", "#193fff");
                        } else {
                            $(this).closest('.toppings-item').css("border-color", "#ccc");
                        }
                    }
                });

                // increment
                el.parent().on('click', '.addSign', function () {
                    if (el.val() < parseInt(el.attr('max'))) {
                        el.val(function (i, oldval) {
                            return ++oldval;
                        });
                    }

                    if ($(window).width() <= 991) {
                        if (el.val() > 0) {
                            $(this).closest('.toppings-item').find('.toppings-price').css("color", "#193fff");
                        } else {
                            $(this).closest('.toppings-item').find('.toppings-price').css("color", "#91969f");
                        }
                    } else {
                        if (el.val() > 0) {
                            $(this).closest('.toppings-item').css("border-color", "#193fff");
                        } else {
                            $(this).closest('.toppings-item').css("border-color", "#ccc");
                        }
                    }
                });
            });
        };
    })(jQuery);

    $('.spinner').spinner();

    // setting border color for addon item container when checkbox is marked
    $('.confirm-addon input[type="checkbox"]').on('click', function () {
        if ($(window).width() <= 991) {
            if ($(this).is(":checked")) {
                $(this).closest('.addon-product-item, .cheese-types-item')
                    .find('.discounted-price, .cheese-types-price')
                    .css("color", "#193fff");
            } else {
                $(this).closest('.addon-product-item, .cheese-types-item')
                    .find('.discounted-price, .cheese-types-price')
                    .css("color", "#91969f");
            }
        } else {
            if ($(this).is(":checked")) {
                $(this).closest('.addon-product-item, .cheese-types-item').css("border-color", "#193fff");
            } else {
                $(this).closest('.addon-product-item, .cheese-types-item').css("border-color", "#ccc");
            }
        }
    });
    // Show cart on mobile menu
    $(".mobileCart").click(function () {
        if ($("#collapseMobileCart").hasClass("show")) {
            setTimeout(function () {
                $("#right-column").css("height", "90px");
                $(".mobileCart-arrow-up").css("display", "block");
                $(".mobileCart-arrow-down").css("display", "none");
                $(".order-summary-right").css("display", "block");

                if ($('.confirm-add-to-cart-container').css('display') === 'none') {
                    $("body").attr("style", "");
                }
            }, 150);
        } else {
            $("body").css("overflow", "hidden");
            $("#right-column").css("height", "unset");
            $(".mobileCart-arrow-up").css("display", "none");
            $(".mobileCart-arrow-down").css("display", "block");
            $(".order-summary-right").css("display", "none");
        }
    });

    $(document).on('submit', '.confirm-product-qty-order-container form', (e) => {
        e.preventDefault();

        $("#react_productcustomize_popup .react_productcustomize_popup_content").css({'opacity': '0'})
        $(".product-additions-details .loader-post-add-to-cart-container").css({'opacity': '1', 'display': 'flex'});

        const addToCartBtn = document.querySelector('.confirm-product-qty-order-container .add-to-cart');
        const productId = parseInt($(this).find('input[name="id_product"]').val());
        $(`#content-wrapper .products input[name="id_product"][value="${productId}"]`)
            .closest('form').find('[data-button-action="add-to-cart"]').trigger("click");

        addToCartBtn.setAttribute('disabled', 'true');
        setTimeout(() => {
            $('.cancel-icon-container').trigger('click');
            $("#react_productcustomize_popup .react_productcustomize_popup_content").css({'opacity': '1'})
            $(".product-additions-details .loader-post-add-to-cart-container").css({'opacity': '0', 'display': 'none'});
        }, 2500)

        return false;
    });

    $("#myInput").on("input", function () {
        // Print entered value in a div box
        $("#result").text($(this).val());
    });

    let addToCartButtonContainerHiddenHeight = 90;
    let container = document.querySelector('.confirm-add-to-cart-container');
    let addToCartButtonContainer = document.querySelector('.confirm-product-qty-order');
    window.productCustomizePopup_refreshButtonVisibility = () => {
        if ($(window).width() > 768) {
            addToCartButtonContainer.style.height = 'initial';
            return;
        }
        let x = (container.scrollHeight - container.scrollTop - container.clientHeight);
        if ((addToCartButtonContainerHiddenHeight - x) > 0) {
            addToCartButtonContainer.style.height = (80 + (addToCartButtonContainerHiddenHeight - x)) + 'px';
        } else {
            addToCartButtonContainer.style.height = '80px';
        }
    }
    container.addEventListener('scroll', window.productCustomizePopup_refreshButtonVisibility);
    $(window).on('resize', window.productCustomizePopup_refreshButtonVisibility);

    $("#content-wrapper .add-to-customize").on("click", function () {

        let productId = parseInt($(this).parent().find('input[name="id_product"]').eq(0).val());
        let productPriceBase = parseFloat($(this).parent().find('input[name="price_base"]').eq(0).val());

        $('body').css("overflow", "hidden");
        $('.confirm-add-to-cart-container').fadeIn();

        $(this).closest('.thumbnail-container').children('.product-img-description')
            .clone().prependTo(".confirm-product-info-content");

        const html = $(this).closest('.product-qty-order').find('.add').html();
        $(".confirm-product-qty-order-container .add").append(html);

        $('.confirm-product-info-content .product-details').removeClass('d-none d-xl-block');
        $('.confirm-product-info-content .price').removeClass('d-flex d-xl-none').css('display', 'none');

        $('.confirm-product-qty-order .product-serving').removeClass('d-none d-xl-flex');
        $('.confirm-product-qty-order .price').removeClass('d-none d-xl-flex');

        if ($(window).width() <= 991) {
            $('.confirm-product-qty-order-container .product-price-and-shipping')
                .appendTo('.confirm-product-qty-order-container .price-confirm-container');
            $('section.confirm-product-qty-order').appendTo('.confirm-product-info-content');
        }

        // Swapping product price element to make easier to style
        $(window).on('resize', function () {
            if ($(window).width() <= 991) {
                $('.confirm-product-qty-order-container .product-price-and-shipping')
                    .appendTo('.confirm-product-qty-order-container .price-confirm-container');
                $('section.confirm-product-qty-order').appendTo('.confirm-product-info-content');
            } else {
                $('.confirm-product-qty-order-container .price-confirm-container .product-price-and-shipping')
                    .prependTo('.confirm-product-qty-order-container .add');
            }
        });

        // Change add to cart button text
        $(document).on('click', '.confirm-product-qty-order-container .add-to-cart', function (e) {
            $(this).addClass("in-cart");
            $(".in-cart").css("display", "block");

            $(this).children(".div-o-cart").css("display", "none");
            $(this).children(".div-i-cart").css("display", "block");

            let $this = (this);
            setTimeout(function () {
                $($this).removeClass("in-cart");
                $($this).children(".div-o-cart").css("display", "block");
                $($this).children(".div-i-cart").css("display", "none");
            }, 2500);


        });

        // Hide popup
        $(document).on("click", '.cancel-icon-container, .confirm-add-to-cart-container', function () {
            $('body').css("overflow", "");
            $('.confirm-add-to-cart-container').fadeOut(() => {
                $(".confirm-product-qty-order-container .add").empty();
                $(".confirm-product-qty-order-container .price-confirm-container").empty();
                $(".confirm-product-info-content .product-img-description").remove();

                $('.product-serving-price').removeClass('product-serving-price-fixed')
                $('.confirm-product-qty-order').removeAttr('style');

            });
        });

        ReactDOM.render(
            <ProductCustomize_Popup productId={productId} productPriceBase={productPriceBase}/>,
            document.getElementById('react_productcustomize_popup')
        );
    })

    // Prevent those elements from click event to hiding popup
    $(".confirm-product-info-content, .confirm-product-qty-order, .cheese-types-list").click(function (e) {
        e.stopPropagation();
    });
});
