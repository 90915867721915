/* globals prestashop */

import Cookies from 'js-cookie'

$(() => {

    const addressSelectorInput = $("#header .enterLocationContainer input[type=\"search\"]");
    const addressSelectorInfoInitial = $('header .enterLocationContainer [data-type="initial"]');
    const addressSelectorInfoError = $('header .enterLocationContainer [data-type="error"]');
    const addressSelectorInfoSuccess = $('header .enterLocationContainer [data-type="success"]');

    const addressSelectorSearchBar = $('header .enterLocationContainer hr.searchingBar');

    addressSelectorInput.autocomplete({
        minLength: 3,
        search: function (event, ui) {
            addressSelectorSearchBar.addClass('searchingBarActive')
        },

        source: (request, response) => {
            $.ajax({
                url: prestashop.urls.base_url + 'module/pshowdeliveryzones/api',
                data: {
                    q: request.term
                },

                success: (data) => {

                    response(data.suggested);

                    addressSelectorInfoInitial.hide();
                    addressSelectorInfoError.hide();
                    addressSelectorInfoSuccess.hide();
                    if (data.suggested.length === 0) {
                        addressSelectorInfoError.show();
                    } else {
                        addressSelectorInfoInitial.show();
                    }

                    changeEnterLocationBorderColor()

                    addressSelectorSearchBar.removeClass('searchingBarActive')
                },
                error: () => {
                    addressSelectorInfoInitial.hide();
                    addressSelectorInfoSuccess.hide();
                    addressSelectorInfoError.show();

                    changeEnterLocationBorderColor()

                    addressSelectorSearchBar.removeClass('searchingBarActive')
                },
            });
        },
        position: {my: "right top", at: "right bottom+17"},
        select: (event, ui) => {
            addressSelectorInput.val(ui.item.value);
            addressSelectorInfoInitial.hide();
            addressSelectorInfoSuccess.show();
            addressSelectorInfoError.hide();

            changeEnterLocationBorderColor()

            // save value to cookie
            Cookies.set('addressSelectorValue', ui.item.value);
        },
        change: function (event, ui) {
            if ($(this).val().trim().length === 0) {
                addressSelectorInfoError.hide();
                addressSelectorInfoSuccess.hide();
                addressSelectorInfoInitial.show();

                changeEnterLocationBorderColor()

                addressSelectorSearchBar.removeClass('searchingBarActive')
                Cookies.remove('addressSelectorValue');
            }
        },
    });

    // restore value from cookie
    const addressSelectorValue = Cookies.get('addressSelectorValue');
    if (addressSelectorValue && addressSelectorValue.length > 0) {
        addressSelectorInput.val(addressSelectorValue);
        addressSelectorInfoInitial.hide();
        addressSelectorInfoSuccess.show();
        addressSelectorInfoError.hide();

        changeEnterLocationBorderColor()
    }

    function changeEnterLocationBorderColor() {
        if ($('.underFormInfo').find('span[style=""]').attr('data-type') == "initial" ||
            (addressSelectorValue && addressSelectorValue.length <= 0)) {
            $('.enterLocation').css({'border-color': 'transparent'})
        } else {
            $('.enterLocation').css({'border-color': $('.underFormInfo').find('span[style=""]').css('color')})
        }
    }
});
