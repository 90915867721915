$(function () {
    // variable checkPageContentInterval is responsible for
    let checkPageContentLeftPositionInterval;
    // toggle menu on icon click
    $(document).on('click', '.header-nav .menu-icon', function () {
        $('.main-content').css({'position': 'absolute', 'overflow': 'hidden', 'width': '100%'});

        $('body').toggleClass('show-left-column-mobile');

        checkPageContentLeftPositionInterval = setInterval(checkPagePositionLeft, 1)
    });

    // toggle menu on touch move
    let touchLengthToShowMenu = 100;
    let touchStartX;
    $(document).on('touchstart', function (e) {
        let touch = e.originalEvent.touches[0] || e.originalEvent.changedTouches[0];
        touchStartX = touch.pageX;
    });
    $(document).on('touchend', function (e) {
        let touch = e.originalEvent.touches[0] || e.originalEvent.changedTouches[0];
        let touchEndX = touch.pageX;
        let moveLength = touchEndX - touchStartX;
        let body = $('body');

        if (moveLength > touchLengthToShowMenu && !body.hasClass('show-left-column-mobile')) {
            body.toggleClass('show-left-column-mobile');
        } else if (moveLength < -touchLengthToShowMenu && body.hasClass('show-left-column-mobile')) {
            $('.main-content').css({'position': 'absolute', 'overflow': 'hidden', 'width': '100%'});
            body.toggleClass('show-left-column-mobile');

            checkPageContentLeftPositionInterval = setInterval(checkPagePositionLeft, 1)
        }
    });

    function checkPagePositionLeft() {
        if ($('.main-content').position().left === 0) {
            clearIntervalAndInternalCss();
        }
    }

    function clearIntervalAndInternalCss() {
        clearInterval(checkPageContentLeftPositionInterval);
        $('.main-content').removeAttr("style");
    }

    // move menu/left-column content based on screen size
    let leftMenuContentInStandardLocation = true; // true mean left column visible for desktops
    $(window).resize(function () {
        let sizeX = $(this).width();
        let bodyId = $('body').attr('id');
        let swapContent;
        let leftColumnMobile = $('#left-column-mobile');

        // Check body id
        if (bodyId !== 'index') {
            swapContent = [$('.logged-nav > .container'), $('.header-nav #_desktop_top_menu')];
            let swapContentAmount = swapContent.length;

            if (sizeX > 991 && !leftMenuContentInStandardLocation) {
                leftMenuContentInStandardLocation = true;

                for (let i = swapContentAmount - 1; i >= 0; i--) {
                    swapContent[1].append($('#left-column-mobile').find('ul').eq(0)).show();
                    swapContent[0].append($('#left-column-mobile').find('ul').eq(0)).show();
                }

                leftColumnMobile.children().not('.logoContainer').remove();

                // hide mobile menu if resized for desktop
                let body = $('body');
                if (body.hasClass('show-left-column-mobile')) {
                    body.toggleClass('show-left-column-mobile');
                }
            } else if (sizeX <= 991 && leftMenuContentInStandardLocation) {
                for (let i = 0; i < swapContentAmount; i++) {
                    leftMenuContentInStandardLocation = false;
                    leftColumnMobile.append(swapContent[i].html());
                    swapContent[i].html('').hide();
                }
            }
        } else {
            swapContent = $('#wrapper #left-column');

            if (sizeX > 991 && !leftMenuContentInStandardLocation) {
                leftMenuContentInStandardLocation = true;
                $('#left-column-mobile .logoContainer').remove();
                swapContent.html(leftColumnMobile.html()).show();
                leftColumnMobile.html('');

                // hide mobile menu if resized for desktop
                let body = $('body');
                if (body.hasClass('show-left-column-mobile')) {
                    body.toggleClass('show-left-column-mobile');
                }
            } else if (sizeX <= 991 && leftMenuContentInStandardLocation) {
                leftMenuContentInStandardLocation = false;
                leftColumnMobile.append(swapContent.html());
                leftColumnMobile.prepend($('.main-content .logoContainer').clone());
                swapContent.html('').hide();
            }
        }


    }).resize();
});

/* Swipe to open/close shoping card */
$(function () {

    let touchLengthToShowMenu = 1;
    let touchStartY;
    let whereClicker;

    $(document).on('touchstart', function (e) {
        whereClicker = $(e);
        let touch = e.originalEvent.touches[0] || e.originalEvent.changedTouches[0];
        touchStartY = touch.pageY;

        let x = e.target.className;
        if (
            x == ("mobileCart") ||
            x == ("mobileCart collapsed") ||
            x == ("order-summary-title") ||
            x == ("cart-summary-title") ||
            x == ("mobileCart-details") ||
            x == ("cart-totals-title")
        ) {
            $("body").css("overflow", "hidden");
        }
    });

    $(window).resize(function () {
        if ($(this).width() >= 991 && $('.mobileCart').attr('aria-expanded') === 'true') {
            $('.mobileCart').trigger('click');
            $("body").css("overflow", "unset");
        }
    });

    $(document).on('touchend', function (e) {
        let touch = e.originalEvent.touches[0] || e.originalEvent.changedTouches[0];
        let touchEndY = touch.pageY;
        let moveLength = touchStartY - touchEndY;
        let windowHeight = $(window).height();
        if (moveLength < -touchLengthToShowMenu) {
            let heightOpen = $("#right-column").height() + 25;
            if (touchStartY - whereClicker[0].view.pageYOffset > (windowHeight - heightOpen) && $("#collapseMobileCart").hasClass("show")) {
                $(".mobileCart ").click();
            }
        } else if (moveLength > touchLengthToShowMenu) {
            if (
                touch.target.className == ("mobileCart") ||
                touch.target.className == ("mobileCart collapsed") ||
                touch.target.className == ("order-summary-title") ||
                touch.target.className == ("cart-summary-title") ||
                touch.target.className == ("mobileCart-details") ||
                touch.target.className == ("cart-totals-title")
            ) {
                $(".mobileCart ").click();
            }
        }
    });
});
